import { graphql } from 'gatsby'
import { groupBy, sortBy, union } from 'lodash'
import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import Banner from '../components/banners/Banner'
import DoubleBanner from '../components/DoubleBanner'
import { navigate } from '../components/Link'
import TileList, { StyledTileList } from '../components/TileList'
import { breakpoints, colors, homeLayout } from '../theme'
import { EventContext } from '../components/context/EventContext'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import CategoriesDropdown from '../components/CategoriesDropdown'
import NbGuestsDropdown from '../components/NbGuestsDropdown'
import CitiesDropdown from '../components/CitiesDropdown'
import prependProtocol from '../util/prepend-protocol'
import { StyledProfileDropdown } from '../components/ProfileDropdown'
import PolygonBanner, { StyledPolygonBanner } from '../components/PolygonBanner'
import { ParallaxProvider } from 'react-scroll-parallax'
import ParallaxShape, { StyledParallaxShape } from '../components/ParallaxShape'
import EventCard from '../components/EventCard'
import GoogleRatingContainer from '../components/GoogleRatingContainer'
import BottomBanner from '../components/banners/BannerBottom'
import MenusGrid from '../components/MenusGrid'

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  lazyLoad: 'progressive',
  swipeToSlide: true,
  draggable: false,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: breakpoints.tablet + 1,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: breakpoints.mobile + 1,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

function buildMetaTags(page) {
  const keywords = page.seoKeywords && page.seoKeywords.seoKeywords
  const description = page.topCta.subText && page.topCta.subText.subText

  const metaTags = [
    { name: 'description', content: description },
    { name: 'keywords', content: keywords },
    { property: 'og:title', content: page.seoTitle },
    { property: 'og:description', content: description },
  ]

  const image =
    page.products && prependProtocol(page.products[0].picture.fixed.src)
  if (image && image.length) {
    metaTags.push({ property: 'og:image', content: image })
  }

  return metaTags
}

// get the latest 5 numbers from the url or null
function getZipcodeFromUrl(url) {
  const matches = url.match(/\/\d{5}$/g)
  return matches ? parseInt(matches[0].substring(1)) : null
}

const CategoryPage = ({
  data: { menus, page, homePage },
  pageContext,
  ...props
}) => {
  const {
    state: { nbGuests, zipcode, category },
    dispatch,
  } = useContext(EventContext)

  // we use the zipcode from the page first, and 75000 if absent
  const pageZipcode = getZipcodeFromUrl(props.location.pathname) || 75000

  useEffect(() => {
    dispatch({
      type: 'UPDATE',
      payload: {
        category: page.slug,
        zipcode: pageZipcode,
      },
    })
  }, [])

  if (!menus) {
    menus = { edges: [] }
  }

  const defaultGuest = 30
  const menusGroupedByNbGuests = groupBy(menus.edges, 'node.nbGuests')
  const nbGuestList = union(
    //[nbGuests],
    Object.keys(menusGroupedByNbGuests).map(k => parseInt(k)),
  ).sort((a, b) => a - b)

  let matchingMenus = sortBy(
    menusGroupedByNbGuests[defaultGuest],
    'node.sellingPricePerPerson',
  )

  if (category == 'lunchbox') {
    matchingMenus = sortBy(
      menusGroupedByNbGuests[1],
      'node.sellingPricePerPerson',
    )
  }

  const noMenus = !matchingMenus || !matchingMenus.length

  const personnalizeMenuCTA = {
    buttonText: 'Obtenir un devis',
    text: 'Vous souhaitez un menu sur-mesure pour votre événement ?',
    subText: {
      subText:
        '<b>Avec plus de 50 artisans partenaires, les possibilités sont infinies !</b><br/>Sucré &#8226; Salé &#8226; Softs &#8226; Alcools &#8226; Café/Thé &#8226; Matériel Décoration &#8226; Animations',
    },
  }

  return (
    <StyledCategoryPage>
      {/*<Helmet title={page.seoTitle} meta={buildMetaTags(page)} />*/}
      <Navbar />
      <ParallaxProvider>
        <div className="page-content">
          <PolygonBanner picture={page.topCta.picture} color="#F0E4D2">
            <ParallaxShape
              x={20}
              y={55}
              shapeStyle={{
                height: '30px',
                width: '30px',
                borderRadius: '50%',
                border: '10px solid ' + colors.brand.normal,
              }}
            />
            <ParallaxShape
              x={60}
              y={-15}
              shapeStyle={{
                height: '30px',
                width: '30px',
                borderRadius: '50%',
                border: '10px solid ' + colors.brand.green,
              }}
            />
            <ParallaxShape
              x={5}
              y={45}
              shapeStyle={{
                height: '24px',
                width: '24px',
                borderRadius: '50%',
                border: '12px solid ' + colors.brand.green,
              }}
            />
            <ParallaxShape
              x={70}
              y={0}
              shapeStyle={{
                width: '35px',
                height: '19px',
                border: '10px solid #030101',
                borderBottom: 'none',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                transform: 'matrix(-0.87, 0.48, -0.48, -0.87, 0, 0)',
              }}
            />
            <ParallaxShape
              x={10}
              y={30}
              shapeStyle={{
                width: '26px',
                height: '33px',
                top: '30%',
                left: '-40%',
                background: '#BC8046 0% 0% no-repeat padding-box',
                clipPath: 'polygon(0% 0%, 25% 0%, 100% 100%, 75% 100%)',
              }}
            />
            <div className="cta-container">
              <h1>{page.topCta.text}</h1>
              <div className="shape"></div>
              {page.topCta.subText && <p>{page.topCta.subText.subText}</p>}
              <div className="dropdowns-container">
                <CategoriesDropdown
                  onChangeCategory={cat => navigate(`/${cat}/${zipcode}`)}
                />
                {/*category != 'lunchbox' && (
                  <NbGuestsDropdown guestsItems={nbGuestList} />
                )*/}
                <CitiesDropdown
                  onChangeZip={zip =>
                    navigate(`/${category}/${zip}`, { replace: true })
                  }
                />
              </div>
            </div>
          </PolygonBanner>
          <div className="category-zip-container">
            <section>
              {noMenus ? (
                <p className="no-menu">{`Aucun menu disponible pour ${page.title} / ${zipcode} / ${nbGuests} pers.`}</p>
              ) : (
                <MenusGrid
                  menus={matchingMenus}
                  category={category}
                  pageContext={pageContext}
                />
              )}
              <BottomBanner bottomBanner={personnalizeMenuCTA} />
            </section>
            <div className="banner1-container">
              <ParallaxShape
                x={2}
                y={60}
                shapeStyle={{
                  width: '200px',
                  height: '400px',
                  border: '140px solid ' + colors.brand.beige,
                  borderLeft: 'none',
                  borderBottomRightRadius: '800px',
                  borderTopRightRadius: '800px',
                  transform: 'matrix(-0.87, 0.5, -0.5, -0.87, 0, 0)',
                  opacity: '0.5',
                }}
              />
              <Banner {...homePage.banner1} />
            </div>
            <div className="events-container">
              <ParallaxShape
                x={80}
                y={-5}
                shapeStyle={{
                  width: '400px',
                  height: '200px',
                  border: '140px solid ' + colors.brand.beige,
                  borderBottom: 'none',
                  borderTopLeftRadius: '400px',
                  borderTopRightRadius: '400px',
                  transform: 'matrix(-0.87, 0.5, -0.5, -0.87, 0, 0)',
                  opacity: '0.5',
                }}
              />
              <ParallaxShape
                x={10}
                y={25}
                shapeStyle={{
                  width: '400px',
                  height: '400px',
                  border: '140px solid ' + colors.brand.beige,
                  borderRadius: '50%',
                  opacity: '0.5',
                }}
              />
              <TileList
                settings={{
                  ...settings,
                  rows: 2,
                  arrows: false,
                  responsive: [
                    {
                      breakpoint: breakpoints.desktop + 1,
                      settings: {
                        rows: 1,
                        slidesToShow: 2,
                        arrows: true,
                      },
                    },
                    {
                      breakpoint: breakpoints.mobile + 1,
                      settings: {
                        rows: 1,
                        slidesToShow: 1,
                        arrows: false,
                      },
                    },
                  ],
                }}
                centered={true}
                title={homePage.eventShowcaseList.title}
                subTitle="Et ils sont ravis"
                tiles={homePage.eventShowcaseList.events}
                renderTile={<EventCard />}
              />
            </div>
            <GoogleRatingContainer />
            <DoubleBanner left={homePage.banner2} right={homePage.banner3} />
            <BottomBanner bottomBanner={homePage.bottomCta} />
          </div>
        </div>
        <Footer />
      </ParallaxProvider>
    </StyledCategoryPage>
  )
}

const StyledCategoryPage = styled.div`
  overflow: hidden;

  ${StyledProfileDropdown} {
    display: none;
  }
  ${StyledPolygonBanner} {
    .cta-container {
      max-width: 750px;

      h1 {
        font-size: 60px;
        height: 70px;
        color: #bc8046;
        margin-bottom: 10px;
      }

      p {
        font-size: 24px;
        margin-bottom: 20px;
      }

      .dropdowns-container {
        display: flex;
        justify-content: space-between;
        height: 66px;

        > div {
          width: 32%;
        }

        ul {
          max-height: 150px;
        }
      }
    }
  }

  .category-zip-container {
    position: relative;
    background-color: ${colors.background.light};
  }

  .menus-container {
    position: relative;
  }

  .no-menu {
    padding-top: 40px;
    text-align: center;
  }

  .banner1-container {
    position: relative;
    margin-top: 40px;
  }

  ${StyledTileList} {
    position: relative;
    width: ${homeLayout.width};
    margin: auto;
    margin-top: 100px;
  }

  .events-container {
    position: relative;
    margin-top: 171px;
  }

  @media (max-height: 810px) and (min-width: ${breakpoints.desktop}px) {
    ${StyledPolygonBanner} {
      .cta-container {
        max-width: 650px;

        h1 {
          font-size: 45px;
          height: 50px;
        }

        p {
          font-size: 18px;
          margin-bottom: 12px;
        }

        .dropdowns-container {
          display: flex;
          justify-content: space-between;
          height: 46px;

          > div {
            width: 43%;
            height: 48px;

            button {
              padding: 8px 10px 8px 26px;
            }

            ul {
              max-height: 110px;

              li {
                line-height: 10px;
                padding: 12px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: ${breakpoints.desktop}px) {
    .menus-container
      ${StyledParallaxShape},
      .banner1-container
      ${StyledParallaxShape},
      .events-container
      ${StyledParallaxShape} {
      display: none;
    }
    .shape {
      margin: 13px auto;
      width: 15px;
      height: 15px;
      background: ${colors.brand.normal};
      clip-path: polygon(80% 0, 100% 0, 20% 100%, 0 100%);
    }

    ${StyledTileList} {
      margin-top: 70px;
      width: 100%;
    }

    .thematic-menus-container,
    .events-container {
      width: 85%;
      margin: auto;
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    ${StyledPolygonBanner} {
      .cta-container {
        max-width: 90%;
        margin: auto;
        text-align: center;

        h1 {
          font-size: 33px;
          height: unset;
        }
        p {
          font-size: 15px;
        }
        .dropdowns-container {
          display: none;
        }
      }
    }

    .banner1-container {
      margin-top: 50px;
    }
  }
`

export default CategoryPage
export { StyledCategoryPage }

export const query = graphql`
  query($category: String!, $zipcode: String!) {
    page: contentfulEventTypePage(slug: { eq: $category }) {
      slug
      seoTitle
      seoDescription {
        seoDescription
      }
      seoKeywords {
        seoKeywords
      }
      topCta {
        ...CallToActionFragment
      }
      products {
        ...ProductFragment
      }
      title
    }
    menus: allMenu(
      filter: {
        category: { eq: $category }
        zoneGeo: { in: [$zipcode] }
        visibleOnWebsite: { eq: true }
      }
    ) {
      edges {
        node {
          fileName
          menuId
          menuName
          menuDescription
          sellingPricePerPerson
          nbGuests
          products {
            id
            name
          }
          mainPhotoTransformed {
            name
            childImageSharp {
              fluid {
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
    homePage: contentfulHomePage(fixedId: { eq: "homepage-1" }) {
      banner1 {
        ...BannerFragment
      }
      eventShowcaseList {
        title
        events {
          ...EventShowcaseFragment
        }
      }
      banner2 {
        ...BannerFragment
      }
      banner3 {
        ...BannerFragment
      }
      bottomCta {
        ...CallToActionFragment
      }
    }
  }
`
